import { all, call, put, takeLatest } from "redux-saga/effects";
import { channelListApi } from "src/core/http/service/staticInfo";
import {
  channelListStaticFailed,
  channelListStaticStart,
  channelListStaticSuccess,
} from "../redux/channelListStatic";

function* channelListStaticStartWork() {
  const params = {
    channelCode: "",
    channelName: "",
    limit: 10000,
    offset: 0,
    status: "",
    companyCode: "",
  };

  try {
    const res = yield call(channelListApi, params);
    yield put({
      type: channelListStaticSuccess,
      payload: res,
    });
  } catch (error: any) {
    yield put({
      type: channelListStaticFailed,
    });
  }
}

function* watchChannelListStatic() {
  yield takeLatest(channelListStaticStart.type, channelListStaticStartWork);
}

export default function* channelListStaticSaga() {
  yield all([watchChannelListStatic()]);
}
