import { all, call, put, select, takeLatest } from "redux-saga/effects";
import { portfolioApi } from "src/core/http/service/stockTrading";
import { PortfolioParams } from "src/core/http/service/stockTrading/types";
import { PortfolioRes } from "src/domain/models/stocktrading";
import { RootState } from "src/redux-store/store";
import {
  portfolioAccountsFailed,
  portfolioAccountsStart,
  portfolioAccountsSuccess,
} from "../redux/portfolioAccounts";

function* portfolioAccountsStartWork() {
  const { accountCode } = yield select(
    (state: RootState) => state.stockTrading.formOrder.inputForm
  );
  const params: PortfolioParams = {
    accountCode: accountCode?.value,
  };

  try {
    const res: PortfolioRes = yield call(portfolioApi, params);
    yield put({
      type: portfolioAccountsSuccess,
      payload: res,
    });
  } catch (error: any) {
    yield put({
      type: portfolioAccountsFailed,
    });
  }
}

function* watchPortfolioAccounts() {
  yield takeLatest(portfolioAccountsStart.type, portfolioAccountsStartWork);
}

export default function* portfolioAccountsSaga() {
  yield all([watchPortfolioAccounts()]);
}
