import { toast } from "react-toastify";
import { all, call, delay, put, takeLatest } from "redux-saga/effects";
import { logOutConnectApi } from "src/core/http/service/managementConnect";
import { LogOutConnectParams } from "src/core/http/service/managementConnect/types";
import { errorCode } from "src/languages/errorCode";
import {
  logOutConnectFailed,
  logOutConnectStart,
  logOutConnectSuccess,
} from "../redux/logOutConnect";
import { orderSessionConnectStart } from "../redux/orderSessionConnect";

function* logOutConnectStartWork(action) {
  // store lấy giá trị mbsManageCode truyền lên
  yield delay(300);

  const params: LogOutConnectParams = {
    channelId: action.payload,
  };
  try {
    yield call(logOutConnectApi, params);
    toast.success("Hủy kết nối thành công", {
      autoClose: 4000,
    });
    yield put({
      type: logOutConnectSuccess,
    });
    yield put(orderSessionConnectStart());
  } catch (error: any) {
    yield put({
      type: logOutConnectFailed,
    });
    const errMess = error?.response?.data?.code;
    toast.error(errorCode[errMess], { autoClose: 4000 });
  }
}

function* watchlogOutConnect() {
  yield takeLatest(logOutConnectStart.type, logOutConnectStartWork);
}

export default function* logOutConnectSaga() {
  yield all([watchlogOutConnect()]);
}
