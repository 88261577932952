import { all, call, delay, put, select, takeLatest } from "redux-saga/effects";
import { getBuyInfoApi } from "src/core/http/service/stockTrading";
import { GetBuyInfoParams } from "src/core/http/service/stockTrading/types";
import { GetBuyInfoRes } from "src/domain/models/stocktrading";
import { RootState } from "src/redux-store/store";
import {
  getBuyInfoFailed,
  getBuyInfoStart,
  getBuyInfoSuccess,
} from "../redux/getBuyInfo";

function* getBuyInfoStartWork() {
  yield delay(400);
  const { accountCode, shareCode, price } = yield select(
    (state: RootState) => state.stockTrading.formOrder.inputForm
  );

  const params: GetBuyInfoParams = {
    accountCode: accountCode?.value,
    shareCode: shareCode?.value ? shareCode?.value : "",
    sharePrice: price !== "ATC" || price !== "ATO" ? price : "",
    marketType: price === "ATC" || price === "ATO" ? price : "",
  };
  try {
    const res: GetBuyInfoRes = yield call(getBuyInfoApi, params);
    yield put({
      type: getBuyInfoSuccess,
      payload: res,
    });
  } catch (error: any) {
    yield put({
      type: getBuyInfoFailed,
    });
  }
}

function* watchGetBuyInfo() {
  yield takeLatest(getBuyInfoStart.type, getBuyInfoStartWork);
}

export default function* getBuyInfoSaga() {
  yield all([watchGetBuyInfo()]);
}
