import { format } from "date-fns";
import { all, call, delay, put, select, takeLatest } from "redux-saga/effects";
import {
  listOrdersApi,
  listOrdersUserHandApi,
} from "src/core/http/service/stockTradingHand";
import { ListOrdersParams } from "src/core/http/service/stockTradingHand/types";
import { ListOrdersAccountRes } from "src/domain/models/stockTradingHand";
import { roles as RolesLogin } from "src/helper/const";
import { RootState } from "src/redux-store/store";
import {
  listOrdersHandFailed,
  listOrdersHandStart,
  listOrdersHandSuccess,
} from "../redux/listOrdersHand";

function* listOrdersHandStartWork() {
  yield delay(300);
  const { accountCode } = yield select(
    (state: RootState) => state.stockTradingHand.formOrderHand.inputForm
  );
  const { roles } = yield select(
    (state: RootState) => state.authenication.auth.dataAuth
  );
  const { fromDate, toDate } = yield select(
    (state: RootState) => state.stockTradingHand.filterOrderHand.inputFilter
  );
  const params = {
    from: format(fromDate, "yyyy-MM-dd"),
    to: format(toDate, "yyyy-MM-dd"),
    limit: 10000,
    offset: 0,
    sort: "desc",
  };

  try {
    if (roles[0] !== RolesLogin.ROLE_USER) {
      const paramsEditor: ListOrdersParams = {
        ...params,
        accountCode: accountCode.value,
      };
      const res: ListOrdersAccountRes = yield call(listOrdersApi, paramsEditor);
      yield put({
        type: listOrdersHandSuccess,
        payload: res,
      });
    } else if (roles[0] === RolesLogin.ROLE_USER) {
      const res: ListOrdersAccountRes = yield call(
        listOrdersUserHandApi,
        params
      );
      yield put({
        type: listOrdersHandSuccess,
        payload: res,
      });
    }
  } catch (error: any) {
    yield put({
      type: listOrdersHandFailed,
    });
  }
}

function* watchListOrdersHand() {
  yield takeLatest(listOrdersHandStart.type, listOrdersHandStartWork);
}

export default function* listOrdersHandSaga() {
  yield all([watchListOrdersHand()]);
}
