import { all, call, put, select, takeLatest } from "redux-saga/effects";
import { filterAccountApi } from "src/core/http/service/managementAccount";
import { ResFilterAccount } from "src/domain/models/managementAccount";
import { RootState } from "src/redux-store/store";
import {
  filterAccountFailed,
  filterAccountStart,
  filterAccountSuccess,
} from "../redux/filterAccount";
import { updateFilterTable } from "../redux/filterTable";

function* filterAccountStartWork() {
  // store lấy giá trị mbsManageCode truyền lên
  const { accountCode, accountName, role, status, limit, offset } =
    yield select(
      (state: RootState) => state.managementAccount.filterTable.inputFilter
    );
  const { data } = yield select(
    (state: RootState) => state.managementAccount.filterAccount
  );

  const params = {
    accountCode: accountCode ? accountCode : null,
    accountName: accountName ? accountName : null,
    limit: limit,
    offset: offset,
    status: status ? status : null,
    roles: role ? [role] : null,
  };
  try {
    const res: ResFilterAccount[] = yield call(filterAccountApi, params);
    if (limit + offset > data.length + res.length) {
      yield put({
        type: updateFilterTable,
        payload: {
          hasMore: false,
        },
      });
    } else {
      yield put({
        type: updateFilterTable,
        payload: {
          hasMore: true,
        },
      });
    }
    yield put({
      type: filterAccountSuccess,
      payload: res,
    });
  } catch (error: any) {
    yield put({
      type: filterAccountFailed,
    });
  }
}

function* watchFilterAccount() {
  yield takeLatest(filterAccountStart.type, filterAccountStartWork);
}

export default function* filterAccountSaga() {
  yield all([watchFilterAccount()]);
}
