import { toast } from "react-toastify";
import { all, call, delay, put, select, takeLatest } from "redux-saga/effects";
import { orderActiveConnectApi } from "src/core/http/service/managementConnect";
import { RootState } from "src/redux-store/store";
import { updateFormModal } from "../redux/formModal";
import {
  orderActiveConnectFailed,
  orderActiveConnectStart,
  orderActiveConnectSuccess,
} from "../redux/orderActiveConnect";
import { errorCode } from "src/languages/errorCode";
import { orderSessionConnectStart } from "../redux/orderSessionConnect";
import { OrderActiveConnectParams } from "src/core/http/service/managementConnect/types";

function* orderActiveConnectStartWork() {
  // store lấy giá trị mbsManageCode truyền lên
  yield delay(200);
  const { password, channelId } = yield select(
    (state: RootState) => state.managementConnect.formModal.inputConnect
  );

  const params: OrderActiveConnectParams = {
    password,
    channelId,
  };

  try {
    yield call(orderActiveConnectApi, params);
    toast.success("Mã xác thực đã được gửi tới điện thoại của bạn", {
      autoClose: 4000,
    });
    yield put({
      type: orderActiveConnectSuccess,
    });
    yield put({
      type: updateFormModal,
      payload: {
        showOtp: true,
      },
    });
    yield put(orderSessionConnectStart());
  } catch (error: any) {
    yield put({
      type: orderActiveConnectFailed,
    });
    const errMess = error?.response?.data?.code;
    toast.error(errorCode[errMess], { autoClose: 4000 });
  }
}

function* watchOrderActiveConnect() {
  yield takeLatest(orderActiveConnectStart.type, orderActiveConnectStartWork);
}

export default function* orderActiveConnectSaga() {
  yield all([watchOrderActiveConnect()]);
}
